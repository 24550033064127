import React, { useState } from 'react';
import { royalties } from '../../utils/royalties.js';
import './App.css';

export default function App() {
  const [totalDownloads, setTotalDownloads] = useState(0);
  const [income, setIncome] = useState(0);
  const [categoryPercentages, setCategoryPercentages] = useState({});

  const handleDownloadsChange = (e) => {
    setTotalDownloads(Number(e.target.value));
  };

  const handleIncomeChange = (e) => {
    setIncome(Number(e.target.value));
  };

  const handlePercentageChange = (categoryKey, percentage) => {
    setCategoryPercentages((prevState) => ({
      ...prevState,
      [categoryKey]: Number(percentage)
    }));
  };

  const calculateLevel = (downloads, levels) => {
    for (let i = 0; i < levels.length; i++) {
      const [min, max] = levels[i].downloads.split('-').map(Number);
      if ((downloads >= min) && (max ? downloads <= max : true)) {
        return levels[i];
      }
    }
    return { level: 'N/A', downloads: 'N/A', royalties: 'N/A' };
  };

  const calculateEarningWith50PercentRoyalties = (currentEarning, currentRoyaltyPercentage) => {
    return (currentEarning / (currentRoyaltyPercentage / 100) * 0.5).toFixed(2);
  };

  const calculatePricePerDownload = (totalIncome, royaltyPercentage, downloads) => {
    return downloads > 0 ? ((totalIncome * (royaltyPercentage / 100)) / downloads).toFixed(2) : 'N/A';
  };

  let totalEarnings = 0;
  let totalEarningsWith50PercentRoyalties = 0;

  return (
    <div className="container">
      <div>
        <label>
          Количество скачиваний:
          <input type="number" value={totalDownloads} onChange={handleDownloadsChange} />
        </label>
        <label>
          Доход:
          <input type="number" value={income} onChange={handleIncomeChange} />
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>Категория</th>
            <th>Процент категории</th>
            <th>Уровень</th>
            <th>Скачиваний</th>
            <th>Роялти %</th>
            <th>Заработок</th>
            <th>Заработок 50%</th>
            <th>Разница</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(royalties).map((key) => {
            const category = royalties[key];
            const percentage = categoryPercentages[key] || 0;
            const categoryDownloads = Math.round((percentage / 100) * totalDownloads);
            const { level, downloads, royalties: royaltyPercentage } = calculateLevel(categoryDownloads, category.levels);
            const earning = ((percentage / 100) * income).toFixed(2);
            const earningWith50PercentRoyalties = calculateEarningWith50PercentRoyalties(earning, royaltyPercentage);
            const pricePerDownload = calculatePricePerDownload(income * (percentage / 100), royaltyPercentage, categoryDownloads);

            totalEarnings += parseFloat(earning);
            totalEarningsWith50PercentRoyalties += parseFloat(earningWith50PercentRoyalties);

            const difference = (earningWith50PercentRoyalties - earning).toFixed(2);

            return (
              <tr key={key}>
                <td>{category.name}</td>
                <td>
                  <input
                    type="number"
                    value={percentage}
                    onChange={(e) => handlePercentageChange(key, e.target.value)}
                  />
                </td>
                <td>{level}</td>
                <td>{categoryDownloads}</td>
                <td>{royaltyPercentage}%</td>
                <td>${earning}</td>
                <td>${earningWith50PercentRoyalties}</td>
                <td>${difference}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='total'>
        <div>
        <p>Заработок при 50% Роялти: ${totalEarningsWith50PercentRoyalties.toFixed(2)}</p>
        <p>Разница: ${(totalEarningsWith50PercentRoyalties - totalEarnings).toFixed(2)}</p>
        <p>Разница в процентах: {((totalEarningsWith50PercentRoyalties / totalEarnings) * 100 - 100).toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );
}
