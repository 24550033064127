export const royalties = {
    ae: {
        name: "After Effects Templates",
        levels: [
            { level: 1, downloads: "0-20", royalties: 20 },
            { level: 2, downloads: "21-50", royalties: 25 },
            { level: 3, downloads: "51-100", royalties: 30 },
            { level: 4, downloads: "101-200", royalties: 35 },
            { level: 5, downloads: "201-250", royalties: 40 },
            { level: 6, downloads: "251-2900", royalties: 45 },
            { level: 7, downloads: "2901", royalties: 50 }
        ]
    },
    pr: {
        name: "Premiere Pro Templates",
        levels: [
            { level: 1, downloads: "0-25", royalties: 20 },
            { level: 2, downloads: "26-75", royalties: 25 },
            { level: 3, downloads: "76-150", royalties: 30 },
            { level: 4, downloads: "151-300", royalties: 35 },
            { level: 5, downloads: "301-610", royalties: 40 },
            { level: 6, downloads: "611-3500", royalties: 45 },
            { level: 7, downloads: "3501", royalties: 50 }
        ]
    },
    ae_presets: {
        name: "After Effects Presets",
        levels: [
            { level: 1, downloads: "0-40", royalties: 15 },
            { level: 2, downloads: "41-60", royalties: 20 },
            { level: 3, downloads: "61-120", royalties: 25 },
            { level: 4, downloads: "121-250", royalties: 30 },
            { level: 5, downloads: "251-450", royalties: 35 },
            { level: 6, downloads: "451-2450", royalties: 40 },
            { level: 7, downloads: "2451", royalties: 45 }
        ]
    },
    mg: {
        name: "Motion Graphics Templates",
        levels: [
            { level: 1, downloads: "0-25", royalties: 15 },
            { level: 2, downloads: "26-60", royalties: 20 },
            { level: 3, downloads: "61-130", royalties: 25 },
            { level: 4, downloads: "131-220", royalties: 30 },
            { level: 5, downloads: "221-430", royalties: 35 },
            { level: 6, downloads: "431-2500", royalties: 40 },
            { level: 7, downloads: "2501", royalties: 45 }
        ]
    },
    pr_presets: {
        name: "Premiere Pro Presets",
        levels: [
            { level: 1, downloads: "0-10", royalties: 15 },
            { level: 2, downloads: "11-25", royalties: 20 },
            { level: 3, downloads: "26-60", royalties: 25 },
            { level: 4, downloads: "61-140", royalties: 30 },
            { level: 5, downloads: "141-400", royalties: 35 },
            { level: 6, downloads: "401-3650", royalties: 40 },
            { level: 7, downloads: "3651", royalties: 45 }
        ]
    },
    fcp: {
        name: "Final Cut Pro Templates",
        levels: [
            { level: 1, downloads: "0-150", royalties: 15 },
            { level: 2, downloads: "151-350", royalties: 20 },
            { level: 3, downloads: "351-570", royalties: 25 },
            { level: 4, downloads: "571-860", royalties: 30 },
            { level: 5, downloads: "861-1600", royalties: 35 },
            { level: 6, downloads: "1601-4000", royalties: 40 },
            { level: 7, downloads: "4001", royalties: 45 }
        ]
    },
    dr: {
        name: "DaVinci Resolve Templates",
        levels: [
            { level: 1, downloads: "0-50", royalties: 10 },
            { level: 2, downloads: "51-120", royalties: 15 },
            { level: 3, downloads: "121-180", royalties: 20 },
            { level: 4, downloads: "181-310", royalties: 25 },
            { level: 5, downloads: "311-480", royalties: 30 },
            { level: 6, downloads: "481-1850", royalties: 35 },
            { level: 7, downloads: "1851", royalties: 40 }
        ]
    },
    dr_macros: {
        name: "DaVinci Resolve Macros",
        levels: [
            { level: 1, downloads: "0-70", royalties: 10 },
            { level: 2, downloads: "71-140", royalties: 15 },
            { level: 3, downloads: "141-220", royalties: 20 },
            { level: 4, downloads: "221-360", royalties: 25 },
            { level: 5, downloads: "361-750", royalties: 30 },
            { level: 6, downloads: "751-1000", royalties: 35 },
            { level: 7, downloads: "1001", royalties: 40 }
        ]
    },
    pr_rush: {
        name: "Premiere Rush Templates",
        levels: [
            { level: 1, downloads: "0-75", royalties: 10 },
            { level: 2, downloads: "76-180", royalties: 15 },
            { level: 3, downloads: "181-330", royalties: 20 },
            { level: 4, downloads: "331-750", royalties: 25 },
            { level: 5, downloads: "751-1400", royalties: 30 },
            { level: 6, downloads: "1401-2700", royalties: 32 },
            { level: 7, downloads: "2701", royalties: 35 }
        ]
    },
    stock_motion_graphics: {
        name: "Stock Motion Graphics",
        levels: [
            { level: 1, downloads: "0-20", royalties: 10 },
            { level: 2, downloads: "21-60", royalties: 15 },
            { level: 3, downloads: "61-150", royalties: 20 },
            { level: 4, downloads: "151-330", royalties: 25 },
            { level: 5, downloads: "331-1050", royalties: 30 },
            { level: 6, downloads: "1051-7000", royalties: 32 },
            { level: 7, downloads: "7000", royalties: 35 }
        ]
    },
}